<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col class="col-12">
                    <ValidationProvider
                        name="campus_id"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox
                                v-model="form.campus_id"
                                :validate-error="errors[0]"
                            ></campus-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input
                                v-model="form.name"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        ></b-form-invalid-feedback>
                    </ValidationProvider>
                    <ValidationProvider
                        name="name_en"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group prepend="EN">
                            <b-form-input
                                v-model="form.name_en"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                        ></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
            </b-row>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="createForm"
                          :disabled="formLoading"
                          type="button"
                          variant="primary"
                          class="btn-lg mr-2">
                    {{ $t('save').toLocaleUpperCase('tr-TR') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    //Component
    import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import BuildingService from "@/services/BuildingService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,
            CampusSelectbox
        },
        data() {
            return {
                formLoading: false,
                form: {
                    campus_id: null,
                    name: null,
                    name_en: null,
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    BuildingService.store(this.form)
                                 .then((response) => {
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$emit("createFormSuccess")
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 });
                }
            }
        }
    }
</script>
